export const darkStyles = {
  userCredits: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#e0e0e2",
    backgroundColor: "#474E5C",
    padding: "8px 16px",
    borderRadius: "4px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  
    toggleButton: {
        marginTop: "0px",
        marginBottom: "10px",
        right: "10px",
        // ...other styles
      },
    body: {
      fontFamily: "sans-serif",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#e0e0e2",
      backgroundColor: "#282c34",
      padding: "20px",
      textAlign: "left",
    },
    refresh: {
      fontFamily: "sans-serif",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#787878",
      backgroundColor: "#282c34",
      paddingLeft: "4px",
      textAlign: "left",
    },
    h1: {
      marginTop: "0",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      marginLeft: "-4px",
      fontWeight: "bold",
    },
    yourmsgLabel: {
      display: "block",
      marginTop: "30px",
      marginBottom: "8px",
      fontWeight: "bold",
    },
    textarea: {
      width: "100%",
      fontFamily: "monospace",
      color: "#fff",
      backgroundColor: "#313640", // changed background color to #b8bdb5
      border: "1px solid #626C80",
      borderRadius: "4px",
      padding: "8px",
      resize: "vertical",
      fontSize: "14px",
      paddingLeft: "0px",
    },
    button: {
      backgroundColor: "#0080FF", // Celestial Blue: 009FFD, Azure - Original: 0080FF
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      padding: "8px 16px",
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "16px",
      marginBottom: "10px",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#0069d9",
    },
    responseContainer: {
      marginTop: "60px",
      marginBottom: "20px",
      marginRight: "-8px",
      marginLeft: "-8px",
      padding: "20px",
      paddingBottom: "20px",
      borderRadius: "4px",
      backgroundColor: "#474E5C",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
    },
    chatResponseContainer: {
      marginTop: "16px",
      marginBottom: "150px",
      marginRight: "-8px",
      marginLeft: "-8px",
      padding: "20px",
      paddingBottom: "10px",
      borderRadius: "4px",
      backgroundColor: "#474E5C",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
    },
    responseContainerShow: {
      display: "block",
    },
    responseContainerH3: {
      marginTop: "0px",
      fontSize: "18px",
    },
    response: {
      whiteSpace: "pre-wrap",
      fontFamily: "monospace",
      fontSize: "16px",
      marginTop: "8px",
      marginBottom: "8px",
      color: "#e0e0e2",
    },
    loadingSpinner: {
      display: "none",
      marginTop: "16px",
      textAlign: "center",
    },
    loadingSpinnerSvg: {
      width: "30px",
      height: "30px",
    },
    loadingSpinnerCircle: {
      strokeDasharray: "113",
      strokeDashoffset: "113",
      transformOrigin: "center",
      animation: "spin 1s linear infinite, draw 1s linear infinite",
    },
    userMessage: {
        textAlign: 'left',
        fontSize: "16px",
        padding: "8px",
        marginLeft: '-0.5rem',
        backgroundColor: "#565B66",
        borderRadius: '5px',
        color: '#9ad4ff', // Light blue color for user messages in dark mode
        marginBottom: '0.5rem',
        marginRight: '-8px',
        whiteSpace: 'pre-wrap',
      },
      botMessage: {
        textAlign: 'left',
        fontSize: "16px",
        color: '#e6e6e6', // Light gray color for bot messages in dark mode
        marginBottom: '0.5rem',
        marginRight: '-6px',
        whiteSpace: 'pre-wrap',
      },
    chatResponse: {
      borderRadius: "4px",
      marginBottom: "8px",
      padding: "8px",
      fontFamily: "monospace",
      fontSize: "16px",
      backgroundColor: "#b5bad0",
      whiteSpace: 'pre-wrap',
    },
  };
export const lightStyles = {
  userCredits: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#686868",
    backgroundColor: "#fff",
    padding: "8px 16px",
    borderRadius: "4px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  
    toggleButton: {
        marginTop: "0px",
        marginBottom: "10px",
        right: "10px",
        // ...other styles
      },
    body: {
      fontFamily: "sans-serif",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#333",
      backgroundColor: "#f7f7f7",
      padding: "20px",
      textAlign: "left",
    },
    refresh: {
      fontFamily: "sans-serif",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#787878",
      backgroundColor: "#f7f7f7",
      paddingLeft: "4px",
      textAlign: "left",
    },
    h1: {
      marginTop: "0",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      fontWeight: "bold",
    },
    yourmsgLabel: {
      display: "block",
      marginTop: "30px",
      marginBottom: "8px",
      fontWeight: "bold",
    },
    textarea: {
      width: "100%",
      fontFamily: "monospace",
      color: "#333",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderRadius: "4px",
      padding: "8px",
      resize: "vertical",
      fontSize: "14px",
      paddingLeft: "0px",
    },
    button: {
      backgroundColor: "#0080FF", // Celestial Blue: 009FFD, Azure - Original: 0080FF
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      padding: "8px 16px",
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "16px",
      marginBottom: "10px",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#0069d9",
    },
    responseContainer: {
      marginTop: "60px",
      marginBottom: "20px",
      marginRight: "-8px",
      marginLeft: "-8px",
      padding: "20px",
      paddingBottom: "20px",
      borderRadius: "4px",
      backgroundColor: "#fff",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
    },
    chatResponseContainer: {
      marginTop: "16px",
      marginBottom: "150px",
      marginRight: "-8px",
      marginLeft: "-8px",
      padding: "20px",
      paddingBottom: "10px",
      borderRadius: "4px",
      backgroundColor: "#fff",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
    },
    responseContainerShow: {
      display: "block",
    },
    responseContainerH3: {
      marginTop: "0",
      fontSize: "18px",
    },
    response: {
      whiteSpace: "pre-wrap",
      fontFamily: "monospace",
      fontSize: "16px",
      marginTop: "8px",
      marginBottom: "8px",
      color: "#333",
    },
    loadingSpinner: {
      display: "none",
      marginTop: "16px",
      textAlign: "center",
    },
    loadingSpinnerSvg: {
      width: "30px",
      height: "30px",
    },
    loadingSpinnerCircle: {
      strokeDasharray: "113",
      strokeDashoffset: "113",
      transformOrigin: "center",
      animation: "spin 1s linear infinite, draw 1s linear infinite",
    },
    userMessage: {
        textAlign: 'left',
        fontSize: "16px",
        padding: "8px",
        marginLeft: '-0.5rem',
        backgroundColor: "#f7f7f7",
        borderRadius: '5px',
        color: '#719DE3', // 7A9ACC is good and 89ABE3 is standard -719DE3 is new standard
        marginBottom: '0.5rem',
        marginRight: '-10px',
        whiteSpace: 'pre-wrap',
      },
      botMessage: {
        textAlign: 'left',
        fontSize: "16px",
        marginBottom: '0.5rem',
        whiteSpace: 'pre-wrap',
      },
    chatResponse: {
      borderRadius: "4px",
      marginBottom: "8px",
      padding: "8px",
      fontFamily: "monospace",
      fontSize: "16px",
      backgroundColor: "#f7f7f7",
      whiteSpace: 'pre-wrap',
    },
  };