import React, { useState, useRef, useEffect } from "react";
import logo from './logo.svg';
import { darkStyles, lightStyles } from './styles.js';
import './App.css';
import { FixThisCode } from "./ftccore.js";
import useMediaQuery from './useMediaQuery';
import { SignUpForm, SignInForm, signUserOut, getUserData, update, auth, onAuthStateChanged  } from './FirebaseFuncs';
import ToggleSwitch from './ToggleSwitch';
const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);


function App() {
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [context, setContext] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(true);
  const FixThisCodeRef = useRef(null); // create a reference using the useRef hook
  const [isCombinedFunctionTriggered, setIsCombinedFunctionTriggered] = useState(false);
  const isMobile = useMediaQuery(768 * 2); // Adjust the breakpoint value as needed
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationShown, setNotificationShown] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [initialResponse, setInitialResponse] = useState('');
  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [gpt4, setGpt4] = useState(false);  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Notification = ({ message, notificationShown, setNotificationShown }) => {
    const [isVisible, setIsVisible] = useState(true);
  
    useEffect(() => {
      if (notificationShown) {
        const timeoutId = setTimeout(() => {
          setIsVisible(false);
          setNotificationShown(false); // Reset the notificationShown state when the notification is hidden
        }, 5000);
  
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }, [notificationShown, setNotificationShown]);
  
    if (!isVisible || !notificationShown) {
      return null;
    }
  
    const notificationStyle = {
      padding: '10px',
      borderRadius: '4px',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
      backgroundColor: styles.userCredits.backgroundColor,
      color: styles.userCredits.color,
      fontWeight: 'bold',
      position: 'fixed',
      top: '120px',
      right: '20px',
      zIndex: 999,
    };
  
    return (
      <div style={notificationStyle}>
        {message}
      </div>
    );
  };

  async function fetchUserCredits() {
    const { userRef, userData, currentUser } = await getUserData();

    if (currentUser) {
      const displayCreds = Math.round((userData.credits) * 100) / 100;
        setUserCredits(displayCreds);
    }
  }

  // This function takes a customer email and product ID and returns whether the customer has an active subscription to the product
  async function paidUserCheck(customerEmail) {
    try {
      // Get the Stripe customer ID associated with the customer email
      const customer = await stripe.customers.list({ email: customerEmail });
      console.log('Customer:', customer); // Debugging statement

      if (customer.data.length === 0) {
        throw new Error('Customer not found');
      }

      const customerId = customer.data[0].id;

      // List the customer's subscriptions for the specified product
      const subscriptions = await stripe.subscriptions.list({
        customer: customerId,
        price: "price_1MrtA9ET5bakQRi3cPK7yw5p", // Change 'items' object to use 'price' instead of 'product'
        status: 'active',
      });
      console.log('Subscriptions:', subscriptions); // Debugging statement

      // Get the most recent invoice for the customer and check its payment status
      const invoices = await stripe.invoices.list({
        customer: customerId,
        limit: 1,
        status: 'paid',
      });
      console.log('Invoices:', invoices); // Debugging statement

      // Return an object with the subscription status and the most recent payment date
      return {
        customerId,
        hasActiveSubscription: subscriptions.data.length > 0,
        lastPaymentDate: invoices.data.length > 0 ? invoices.data[0].created : null,
      };
    } catch (error) {
      console.error('Failed to check subscription status:', error);
      return {
        error: error.message,
        hasActiveSubscription: false,
        lastPaymentDate: null,
      };
    }
  }
  
  async function refreshUserCredits() {
    const refreshButton = document.querySelector(".fa-sync-alt");
    refreshButton.classList.add("spin-fresh");
    setTimeout(() => {
      refreshButton.classList.remove("spin-fresh");
    }, 1000);
    
    const { userRef, userData, currentUser } = await getUserData();
    if (currentUser) {
      await currentUser.reload();
      if (!userData.emailVerified && currentUser.emailVerified) {
        await update(userRef, { emailVerified: true, credits: userData.credits + 500 });
        const displayCreds = Math.round((userData.credits + 500) * 100) / 100;
        setUserCredits(displayCreds);
      } else {
        const displayCreds = Math.round(userData.credits * 100) / 100;
        setUserCredits(displayCreds);
        const paidUser = await paidUserCheck(userEmail);
        const { hasActiveSubscription, lastPaymentDate } = paidUser;
        const isActiveInDb = userData.isActive || false;
        const lastPaymentDateInDb = userData.paymentDate || 0;
        
        if (hasActiveSubscription) {
          if (!isActiveInDb) {
            // Update the user in the database as an active paying user
            await update(userRef, {
              isActive: true,
              paymentDate: lastPaymentDate,
              credits: 15000,
              creditUpdateDate: Date.now(),
            });
            setNotificationShown(true);
            setNotificationMessage(`Account Updated: Subscription Started - New Credits Added!`);
            setUserCredits(15000);        
          } else if (lastPaymentDate > lastPaymentDateInDb) {
            await update(userRef, {
              isActive: true,
              paymentDate: lastPaymentDate,
              credits: 15000,
              creditUpdateDate: Date.now(),
            });
            setNotificationShown(true);
            setNotificationMessage(`Account Updated: New Payment - New Credits Added!`);
            setUserCredits(15000);        
          } else {
            // Do not add credits
            setNotificationShown(true);
            setNotificationMessage(`Refreshed!`);
          }
        } else {
          if (isActiveInDb) {
            // Update the user in the database as a non-active paying user
            await update(userRef, { isActive: false });
          }
          setNotificationShown(true);
          setNotificationMessage(`Refreshed`);
          const displayCreds = Math.round(userData.credits * 100) / 100;
          setUserCredits(displayCreds);
        }
      }
    }
  }
  
  // Load the state from local storage when the component mounts
  useEffect(() => {
    // const storedError = localStorage.getItem("error");
    // const storedCode = localStorage.getItem("code");
    // const storedContext = localStorage.getItem("context");
    const storedIsDarkMode = localStorage.getItem("isDarkMode");
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    const storedInitialResponse = localStorage.getItem("initialResponse") || "";
    const storedChatHistory = localStorage.getItem("chatHistory") || "[]";
    const showUpgrade = localStorage.getItem("showUp") || "false";
    const storedModel = localStorage.getItem("gpt4") || "false";

    // if (storedError) {
    //   setError(storedError);
    // }
    // if (storedCode) {
    //   setCode(storedCode);
    // }
    // if (storedContext) {
    //   setContext(storedContext);
    // }
    if (storedModel) {
      setGpt4(storedModel === "true");
    }

    if (storedIsDarkMode) {
      setIsDarkMode(storedIsDarkMode === "true");
    }

    if (storedIsLoggedIn) {
      setIsLoggedIn(storedIsLoggedIn === "true");
    }
    setShowUpgradeMessage(showUpgrade === "true");
    setInitialResponse(storedInitialResponse);

    // Load the chat history from local storage
    try {
      const parsedChatHistory = JSON.parse(storedChatHistory);
      setChatHistory(parsedChatHistory);
    } catch (error) {
      console.error("Failed to parse stored chat history:", error);
    }

    // Add an observer for the auth state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        saveIsLoggedIn(true);
        setUserEmail(user.email); // Store the user's email
        fetchUserCredits();
      } else {
        saveIsLoggedIn(false);
        setUserEmail(''); // Clear the email when the user is signed out
      }
    });

    // Clean up the observer on component unmount
    return () => {
      unsubscribe();
    };
  }, [setInitialResponse, error, code, context]);


  // Update setIsDarkMode to store the state in local storage
  const saveIsDarkMode = (newState) => {
    setIsDarkMode(newState);
    localStorage.setItem("isDarkMode", newState);
  };

  // Update setIsLoggedIn to store the state in local storage
  const saveIsLoggedIn = (newState) => {
    setIsLoggedIn(newState);
    localStorage.setItem("isLoggedIn", newState);
  };

  function openSignUp() {
    setShowSignUp(true);
    setShowSignIn(false);
  }
  
  function openSignIn() {
    setShowSignIn(true);
    setShowSignUp(false);
  }
  
  
  // Function to handle keydown event for a textarea
  function handleTextareaKeydown(e) {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey))  {
      e.preventDefault();
      setIsCombinedFunctionTriggered(true);
    }
  }

  const toggleTheme = () => {
    saveIsDarkMode(!isDarkMode);
  };
  const styles = isDarkMode ? darkStyles : lightStyles;
  return (  
    <div className="App" style={styles.body}>
      <h1 style={{ ...styles.h1, textAlign: "left", fontSize: isMobile ? "2.1rem" : "2.9rem", display: "flex", alignItems: "left", marginTop: isMobile ? "0px" : "8px"}}>
        <img src={logo} className="App-logo" alt="logo" style={{height: isMobile ? "4rem" : "4.5rem", marginRight: "1rem", marginTop: isMobile ? "-6px" : "-3px"}} />
        Fix This Code
      </h1>
      <div>
      <div className="App" style={styles.body}>
        <button onClick={toggleTheme} style={{ ...styles.toggleButton, marginLeft: "-16px", marginTop: "0px", alignContent:  isMobile ? "center" : "left"}}>
          {isDarkMode ? "Use Light Mode" : "Use Dark Mode"}
        </button>
      </div>
      {isLoggedIn && (
      <div className={styles.userCredits}>
        Credits: {userCredits}{" "}
        <button onClick={refreshUserCredits} className="link-button" style={styles.refresh}><i className="fas fa-sync-alt"></i></button>
      </div>)}      
      <div className="centered-container">
        {!isLoggedIn && (
          <>
            <button className="link-button" onClick={openSignUp}>
              Sign Up
            </button>
            <span className="separator">|</span>
            <button className="link-button" onClick={openSignIn}>
              Sign In
            </button>
          </>
        )}
        {isLoggedIn && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '14px', marginTop: "-30px"}}>
            <div>{userEmail}</div>
            <button
              className="link-button"
              onClick={async () => {
                await signUserOut();
                saveIsLoggedIn(false);
                fetchUserCredits();
              }}
            >
              Sign Out
            </button>
          </div>
        )}
      </div>
      {showSignUp && (
        <SignUpForm
          show={showSignUp}
          onClose={() => {
            setShowSignUp(false);
          }}
          openSignIn={() => {
            setShowSignUp(false);
            setShowSignIn(true);
          }}
          setIsLoggedIn={setIsLoggedIn}
          fetchUserCredits={fetchUserCredits} 
          setNotificationMessage={setNotificationMessage} 
          setNotificationShown={setNotificationShown} 
        />
      )}
      {showSignIn && (
      <SignInForm 
      show={showSignIn} 
      onClose={() => setShowSignIn(false)} 
      openSignUp={() => { setShowSignIn(false); setShowSignUp(true); }} 
      setIsLoggedIn={setIsLoggedIn} 
      fetchUserCredits={fetchUserCredits} 
      />)}
      {<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <ToggleSwitch gpt4={gpt4} setGpt4={setGpt4} setNotificationMessage={setNotificationMessage} setNotificationShown={setNotificationShown} setShowUpgradeMessage={setShowUpgradeMessage} userEmail={userEmail} paidUserCheck={paidUserCheck} />
      </div>}
      {notificationMessage && (
        <Notification
          message={notificationMessage}
          notificationShown={notificationShown}
          setNotificationShown={setNotificationShown}
        />
      )}
      <div style={{paddingLeft: "2px", paddingRight: "4px"}}>
        <label htmlFor="error" style={{...styles.label, marginTop: "0px"}}>
          Error:
        </label>
        <textarea
          placeholder="Paste the error message you're getting or describe the problem you're facing..."
          id="error"
          rows="3"
          style={{ ...styles.textarea, marginLeft: "-8px", paddingLeft: "8px"}}
          value={error}
          onChange={(e) => setError(e.target.value)}
          onKeyDown={handleTextareaKeydown}
        />
        </div>
      </div>
      <div style={{paddingLeft: "2px", paddingRight: "4px"}}>
        <label htmlFor="code" style={styles.label}>
          Code:
        </label>
        <textarea
          placeholder="Paste your code here..."
          id="code"
          rows="7"
          style={{ ...styles.textarea, marginLeft: "-8px", paddingLeft: "8px"}}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          onKeyDown={handleTextareaKeydown}
        />
      </div>
      <div style={{paddingLeft: "2px", paddingRight: "4px"}}>
        <label htmlFor="context" style={styles.label}>
          Context (optional):
        </label>
        <textarea
          placeholder="Any additional context needed here..."
          id="context"
          rows="2"
          style={{ ...styles.textarea, marginLeft: "-8px", paddingLeft: "8px"}}
          value={context}
          onChange={(e) => setContext(e.target.value)}
          onKeyDown={handleTextareaKeydown}
        />
      </div>
      <div className={`App ${isDarkMode ? 'dark' : 'light'}`}>
      <FixThisCode
        error={error}
        setError={setError}
        code={code}
        setCode={setCode}
        context={context}
        setContext={setContext}
        isDarkMode={isDarkMode}
        ref={FixThisCodeRef} // pass the reference as a prop
        isCombinedFunctionTriggered={isCombinedFunctionTriggered}
        setIsCombinedFunctionTriggered={setIsCombinedFunctionTriggered}
        isMobile={isMobile}
        setUserCredits={setUserCredits}
        setIsLoggedIn={setIsLoggedIn}
        initialResponse={initialResponse}
        setInitialResponse={setInitialResponse}
        showUpgradeMessage={showUpgradeMessage}
        setShowUpgradeMessage={setShowUpgradeMessage}
        chatHistory={chatHistory}
        setChatHistory={setChatHistory}
        openSignIn={openSignIn}
        openSignUp={openSignUp}
        windowWidth={windowWidth}
      />
    </div>
    </div>
  );
}

export default App;



