import { getEditorMode } from './detectlang.js';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python'; // Import the modes you need
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/mode-swift';
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-ruby";
import 'ace-builds/src-noconflict/theme-dracula'; // default dark - dracula is the shit!
import 'ace-builds/src-noconflict/theme-clouds';// light broooooo thiunk this one is itttt!
import 'ace-builds/src-noconflict/theme-github'; // light
import 'ace-builds/src-noconflict/theme-github'; // light
import 'ace-builds/src-noconflict/theme-xcode'; // light
import 'ace-builds/src-noconflict/theme-merbivore'; // dark, not too bad, but not it
import 'ace-builds/src-noconflict/theme-terminal'; // intense dark
import 'ace-builds/src-noconflict/theme-textmate'; // light


export function AceEditorComponent({ code, type, isDarkMode }) {
    function updateHeight(editor) {
      if (editor) {
        const lineHeight = editor.renderer.layerConfig.lineHeight;
        const lineCount = code.split('\n').length;
        const padding = 20; // Add some padding to account for the editor's top and bottom padding
        
        editor.container.style.height = `${lineCount * lineHeight + padding}px`;
        editor.resize();
      }
    }
    
    
    return (
      <div style={{ position: 'relative', width: '100%', marginRight: '-10px',}}>
        <AceEditor
          mode={getEditorMode(code)}
          theme={isDarkMode ? 'dracula' : 'clouds'}
          value={code}
          readOnly={false}
          fontSize={14}
          name={`code-editor`}
          highlightActiveLine={false}
          editorProps={{ $blockScrolling: true }}
          onLoad={(editor) => updateHeight(editor)}
          style={{ width: '100%' }}
          showLineNumbers={false}
          showGutter={true}
        />
      </div>
    );
  }
  
  export default AceEditorComponent;