import { useState, useEffect } from 'react';

const useMediaQuery = (breakpoint) => {
  const [matches, setMatches] = useState(window.innerWidth * window.devicePixelRatio <= breakpoint);

  useEffect(() => {
    const listener = () => setMatches(window.innerWidth * window.devicePixelRatio <= breakpoint);

    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [breakpoint]);

  return matches;
};

export default useMediaQuery;
