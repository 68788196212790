
function calculateScore(code, patterns) {
    let score = 0;
    let total = 0;
    let matchedPatterns = new Set();
  
    patterns.forEach((pattern) => {
      const matches = (code.match(pattern) || []).length;
      if (matches > 0) {
        score += matches;
        matchedPatterns.add(pattern.toString());
      }
      total += pattern.exec(code)?.length || 0;
    });
  
    const uniqueMatchedPatterns = matchedPatterns.size;
    const uniqueTotalPatterns = patterns.length;
    const uniquePatternScore =
      uniqueTotalPatterns === 0 ? 0 : uniqueMatchedPatterns / uniqueTotalPatterns;
  
    const totalScore = total === 0 ? 0 : score / total;
  
    return totalScore + uniquePatternScore;
  }


function scoreCPlusPlus(code) {
    const patterns = [
        /#include\s*<[^>]+>/g,
        /\b(?:std::)?(?:cout|cin|cerr|clog)\b/g, 
        /using\s+namespace\s+\w+;/g,
        /\b(?:int|double|float|char|void)\b\s+\w+\s*(?:=\s*\w+)?\s*;?\s*\n?/g // Modified regex to include optional semicolon and newline character
    ];
    
    return calculateScore(code, patterns);
}

function scoreCSharp(code) {
    const patterns = [
      /using\s+\w+\s*\.(?:\w+\s*\.)+/g,
    ];
    const additionalPatterns = [
      /\b(?:Console\.WriteLine|Console\.ReadLine)\b/g,
      /System\.Linq;/g,
    ];
  
    let mainPatternsScore = calculateScore(code, patterns);
    let additionalPatternsScore = calculateScore(code, additionalPatterns);
    return mainPatternsScore > 0 ? mainPatternsScore + additionalPatternsScore : 0;
  }
  

function scoreSwift(code) {
    const patterns = [
      /import\s+[Ff]oundation/g,
      /import\s+UIKit/g, // Added for better detection of Swift code
    ];
    const additionalPatterns = [
      /\b(?:print|autoreleasepool|DispatchQueue)\b/g,
      /enum\s+\w+\s*:\s*[A-Za-z]+\s*\{/g,
    ];
  
    let mainPatternsScore = calculateScore(code, patterns);
    let additionalPatternsScore = calculateScore(code, additionalPatterns);
    return mainPatternsScore > 0 ? mainPatternsScore + additionalPatternsScore : 0;
  }

  function scoreGo(code) {
    const patterns = [
      /package\s+\w+/g,
      /import\s*(?:\((?:\s*"[^"]+"\s*)+\)|"[^"]+")/g,
      /func\s+\w+\s*\((?:\s*\w*\s*[A-Za-z0-9_*]+(?:\s*,\s*)?)*\)\s*(?:\([A-Za-z0-9_*]+(?:\s*,\s*)?\))?\s*\{/g,
      /\b(?:var|const)\b\s+\w+\s+[A-Za-z0-9_*]+(?:\s*=\s*[A-Za-z0-9_."']+(?:\s*,\s*)?)*\s*;?\s*\n?/g,
      /\b(?:type)\b\s+\w+\s+(?:struct|interface)\b\s*\{/g, // Added pattern to match type declarations for struct and interface
      /\b(?:make|new)\b\s*\(\s*\w+\s*\)/g, // Added pattern to match make and new function calls
      /\b(?:go)\b\s+\w+\s*\((?:\s*\w*\s*[A-Za-z0-9_*]+(?:\s*,\s*)?)*\)/g, // Added pattern to match goroutine creation
      /\b\w+\s*:=\s*[A-Za-z0-9_.]+\b/g,
    ];
  
    return calculateScore(code, patterns);
  }

function scorePython(code) {
    const patterns = [
        /(?:def|class)\s+\w+/g,
        /import\s+([a-zA-Z_][a-zA-Z0-9_]*)/g,
        /from\s+\w+\s+import/g,
        /\b(?:if|while|for|elif|else)\s*:/g,
        /if\s+__name__\s*==\s*['__"]__main__["']/g,
        /\w+\.\w+\(\)/g, // Match method calls on objects
        /\w+\['\w+'\]/g, // Match dictionary key access
        /\w+\s*=\s*\w+/g // Match variable assignments
    ];
    const additionalPatterns = [
        /import\s+pandas\s+as\s+pd/g,
        /pd\.DataFrame/g,
    ];

    let mainPatternsScore = calculateScore(code, patterns);
    let additionalPatternsScore = calculateScore(code, additionalPatterns);
    return mainPatternsScore > 0 ? mainPatternsScore + additionalPatternsScore : 0;
}


function scorePHP(code) {
    const patterns = [
      /<\?php/g,
    ];
    const additionalPatterns = [
        /\b(?:print|autoreleasepool|DispatchQueue)\b/g,
        /enum\s+\w+\s*:\s*[A-Za-z]+\s*\{/g,
        /@IBOutlet\s+var\s+\w+:\s+\w+\s*\??\s*\{[^\}]*?\}/g, // Matches IBOutlets in Swift UI
        /@IBAction\s+func\s+\w+\(/g, // Matches IBActions in Swift UI
        /\b(?:guard|if|for|while|switch|case|default|return|throw|try|catch|else)\b/g, // Matches common control flow statements
    ];
    
  
    let mainPatternsScore = calculateScore(code, patterns);
    let additionalPatternsScore = calculateScore(code, additionalPatterns);
    return mainPatternsScore > 0 ? mainPatternsScore + additionalPatternsScore : 0;
  }  

  
  function scoreJava(code) {
    const patterns = [
      /package\s+\w+(\.\w+)*;/g,
      /public\s+(final\s+)?(abstract\s+)?(class|interface|enum)\s+\w+/g,
      /\b(?:System\.out\.print(?:ln)?|public\s+(?:void|int|float|double|boolean|char)\s+\w+\s*\(.*\)|new\s+\w+\s*\()/g,
      /(\w+\s+)*\w+\s*=\s*new\s+\w+\s*\(/g,
      /(\w+\s+)*\w+\s*=\s*[\d\.]+[fFdD]*/g,
      /(\w+\s+)*\w+\s*=\s*[\d\.]+[lL]*/g,
      /(\w+\s+)*\w+\s*=\s*".*"/g,
      /(\w+\s+)*\w+\s*=\s*'.*'/g,
      /(\w+\s+)*\w+\s*=\s*true|false/g,
      /(\w+\s+)*\w+\s*=\s*null/g,
      /(\w+\s+)*\w+\s*\+\s*=.*;/g,
      /(\w+\s+)*\w+\s*-\s*=.*;/g,
      /(\w+\s+)*\w+\s*\*\s*=.*;/g,
      /(\w+\s+)*\w+\s*\/\s*=.*;/g,
      /(\w+\s+)*\w+\s*%\s*=.*;/g,
      /(\w+\s+)*\w+\s*\+\+.*;/g,
      /(\w+\s+)*\w+\s*--.*;/g,
      /(\w+\s+)*\w+\s*==.*;/g,
      /(\w+\s+)*\w+\s*!=.*;/g,
      /(\w+\s+)*\w+\s*>\s*=.*;/g,
      /(\w+\s+)*\w+\s*<\s*=.*;/g,
      /(\w+\s+)*\w+\s*>\s*.*;/g,
      /(\w+\s+)*\w+\s*<\s*.*;/g,
      /(\w+\s+)*\w+\s*&&.*;/g,
      /(\w+\s+)*\w+\s*\|\|.*;/g,
      /(\w+\s+)*\w+\s*\?\s*.*\s*:\s*.*;/g,
      /(\w+\s+)*\w+\s*\(\s*\)\s*->.*;/g,
      /(\w+\s+)*\w+\s*->.*;/g,
      /(\w+\s+)*\w+\s*\.\s*\w+\s*\(.*\).*;/g,
      /(\w+\s+)*\w+\s*\.\s*\w+\s*=.*;/g,
      /(\w+\s+)*\w+\s*\.\s*\w+\s*;/g,
      /(\w+\s+)*\w+\s*\[\s*\]\s*\w+\s*=.*;/g,
      /(\w+\s+)*\w+\s*\[\s*\]\s*\w+\s*;/g,
      /(\w+\s+)*\w+\s*\[\s*\]\s*=\s*.*;/g,
      /(\w+\s+)*\w+\s*\[\s*\]\s*;/g,
    ];
  
    return calculateScore(code, patterns);
  }

function scoreJavaScript(code) {
    const patterns = [
        /console\.log\(/g,
        /\b(?:function|const|let|var)\b/g,
        /class\s+\w+\s*(?:extends\s+\w+\s*)?\{/g,
        /import\s+\w+\s+from\s+['"](?:\w+\/)*\w+['"]/g,
    ];
    
    return calculateScore(code, patterns);
}
    
function scoreJSON(code) {
    const patterns = [
        /^{[\s\S]*}$/g,
        /{\s*("[^"]*"\s*:\s*(?:"[^"]*"|[^\{\}\[\]]*),?\s*)*}/g, // matches JSON objects
        /"[^"]*"\s*:\s*(?:"[^"]*"|[^\{\}\[\]]*),?\s*/g, // matches JSON key-value pairs
      ];
    
    return calculateScore(code, patterns);
}

function scoreRuby(code) {
  const patterns = [
      /\bputs\b\s+["'].*?["']|\bputs\b\s+\w+/g,
      /\bclass\b\s+[A-Z]\w*\s*(<\s*[A-Z]\w*\s*)?/g,
      /\bdef\b\s+[a-z_]\w*\s*(\((?:\s*\w+\s*,?)*\))?/g,
      /(?:\bmodule\b|\binclude\b)\s+[A-Z]\w+/g,
  ];

  return calculateScore(code, patterns);
}

function scoreSQL(code) {
  const patterns = [
      /\bSELECT\b\s+[\w\*,]+\s+FROM/gi,
      /\b(?:INNER JOIN|LEFT JOIN|RIGHT JOIN|FULL JOIN)\b/gi,
      /\bINSERT INTO\b\s+\w+/gi,
      /\bUPDATE\b\s+\w+/gi,
      /\bDELETE FROM\b\s+\w+/gi,
      /\bCREATE (?:DATABASE|TABLE)\b\s+\w+/gi,
      /\bALTER TABLE\b\s+\w+/gi,
      /\bDROP (?:DATABASE|TABLE)\b\s+\w+/gi,
      /\bWHERE\b\s+\w+\s*(?:=|<|>|LIKE)\s*\w+/gi
  ];

  return calculateScore(code, patterns)
}


      
export function detectLanguage(code) {
    const languages = [
      { name: 'C++', score: scoreCPlusPlus(code) },
      { name: 'C#', score: scoreCSharp(code) },
      { name: 'Swift', score: scoreSwift(code) },
      { name: 'Python', score: scorePython(code) },
      { name: 'PHP', score: scorePHP(code) },
      { name: 'Java', score: scoreJava(code) },
      { name: 'JavaScript', score: scoreJavaScript(code) },
      { name: 'JSON', score: scoreJSON(code) },
      { name: 'SQL', score: scoreSQL(code) },
      { name: 'Ruby', score: scoreRuby(code) },
      { name: 'Go', score: scoreGo(code) },
    ];
  
    const sortedLanguages = languages.sort((a, b) => b.score - a.score);
    const highestScoreLanguage = sortedLanguages[0];
  
    if (highestScoreLanguage.score === 0) {
      return 'Text';
    }
  
    return highestScoreLanguage.name;
  }
export const getEditorMode = (code) => {
    const language = detectLanguage(code);
    if (language) {
      switch (language) {
        case 'Python':
          return 'python';
        case 'Java':
          return 'java';
        case 'JavaScript':
          return 'java';
        case 'C++':
          return 'c_cpp';
        case 'C#':
          return 'csharp';
        case 'PHP':
          return 'php'; // 
        case 'Json':
          return 'json';
        case 'Swift':
          return 'swift';
        case 'Ruby':
          return 'ruby';
        case 'SQL':
          return 'sql';
        case 'Go':
          return 'java';
        // Add more languages and their corresponding modes here
        default:
          return 'text'; // Fallback mode if no appropriate mode is found
      }
    } else {
      return 'text'; // Fallback mode if no language is detected
    }
  };
