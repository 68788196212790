import { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, get, update } from "firebase/database";
import './SignUpForm.css';
import Modal from './Modal';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREKEY,
  authDomain: process.env.REACT_APP_FIREDOMAIN,
  databaseURL: process.env.REACT_APP_FIREDB,
  projectId: "fixthiscode",
  storageBucket: "fixthiscode.appspot.com",
  messagingSenderId: "1034556908267",
  appId: "1:1034556908267:web:9755eed432a7668816d4ae"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

function goodEmail(email) {
  // Convert email to string
  email = email.toString();
  
    // Check if email contains "+"
    if (email.includes("+")) {
      return false;
    }

  // List of common bad email domains
  const badDomains = [
    'example.com',
    'tempmail.com',
    'mailinator.com',
    'guerrillamail.com',
    '10minutemail.com',
    '20minutemail.com',
    '30minutemail.com',
    'maildrop.cc',
    'trashmail.com',
    'yopmail.com',
    'getnada.com',
    'mytemp.email',
    'spamgourmet.com',
    'mintemail.com',
    'dispostable.com',
    'mailnesia.com',
    'spamgourmet.net',
    'spamgourmet.org',
    'spamex.com',
    'temp-mail.org',
    'mailcatch.com',
    'moakt.com',
    'fakeinbox.com',
    'safetymail.info',
    'tempmail.space',
    'dropmail.me',
    'emailondeck.com',
    'mailforspam.com',
    'tempail.com',
    'mohmal.com',
    'emailtemporar.ro',
    'sharklasers.com',
    'guerrillamailblock.com',
    'pokemail.net',
    'spam4.me',
    'grr.la',
    'burnermail.io',
  ];

  // Extract domain from the email address
  const domain = email.split('@').pop().toLowerCase();

  // Check if the domain is in the list of bad domains
  if (badDomains.includes(domain)) {
    return false;
  }
  return true;
}

// *************************************
// *************************************
// *************************************
// Sign In 
async function signIn(email, password, setIsLoggedIn, onClose, setUserCredits, fetchUserCredits ) {

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    console.log("User signed in successfully:", user);
    fetchUserCredits();
    setIsLoggedIn(true);
    onClose();
    const { userRef, userData, currentUser } = await getUserData();
    const credits = await userData.credits;
    setUserCredits(credits)
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error("Error signing in:", errorCode, errorMessage);
    // Handle the error as appropriate for your application, e.g., show an error message to the user
  }
}

export function SignInForm({ show, onClose, openSignUp, setIsLoggedIn, setUserCredits, fetchUserCredits }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call your sign-in function here with the email and password
    signIn(email, password, setIsLoggedIn, onClose, setUserCredits, fetchUserCredits);
  };

  return (
    <Modal show={show} onClose={onClose}>
    <div className="signup-form-container">
      <h1>Sign In</h1>
      <form onSubmit={handleSubmit} className="signup-form">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button type="submit">Sign In</button>
      </form>
        <p>
          Don't have an account? <button className="link-button" onClick={openSignUp}>Sign up</button>
        </p>
    </div>
    </Modal>
  );
}
// ***************************************************************************
// ***************************************************************************
// ***************************************************************************
// Sign Up
async function signUp(email, password, setIsLoggedIn, onClose, fetchUserCredits, setNotificationMessage, setNotificationShown) {
  const { userRef, userData, currentUser } = await getUserData();
  const credits = await userData.credits;
  if (goodEmail(email)) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("User signed up successfully:", user);

      // Send email verification
      await sendEmailVerification(user);
      console.log("Email verification sent");

      // Add user to the Realtime Database
      const userRef = ref(db, `users/${user.uid}`);
      await set(userRef, {
        email: user.email,
        credits: credits,
      });
      console.log("User added to Realtime Database");

      // Call the onSuccess callback after successful sign up
      fetchUserCredits();
      setIsLoggedIn(true)
      onClose();
      setNotificationMessage('A verification email has been sent. Verify your email now to get 500 free credits.');
      setNotificationShown(true);

    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error("Error signing up:", errorCode, errorMessage);
      // Handle the error as appropriate for your application, e.g., show an error message to the user
    }
  } else { // email is not good - show an error message
    setNotificationMessage('Sign up failed: Invalid email');
    setNotificationShown(true);
  }
}


export function SignUpForm({ show, onClose, openSignIn, setIsLoggedIn, fetchUserCredits, setNotificationMessage, setNotificationShown}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call the sign-up function with the email and password, and onSuccess callback
    signUp(email, password, setIsLoggedIn, onClose, fetchUserCredits, setNotificationMessage, setNotificationShown);
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="signup-form-container">
        <h1>Sign Up</h1>
        <form onSubmit={handleSubmit} className="signup-form">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button type="submit">Sign Up</button>
        </form>
        <p>
          Already have an account?{" "}
          <button className="link-button" onClick={openSignIn}>
            Sign in
          </button>
        </p>
      </div>
    </Modal>
  );
}

export async function getUserData() {
  const response = await fetch("https://api.ipify.org?format=json");
  const data = await response.json();
  const userIP = data.ip;
  const safeUserIP = userIP.replace(/\./g, '_');
  const currentUser = auth.currentUser;
  
  let userRef;
  
  if (currentUser) {
    userRef = ref(db, `users/${currentUser.uid}`);
  } else {
    userRef = ref(db, `publication/${safeUserIP}`);
  }
  
  const snapshot = await get(userRef);
  let userData = snapshot.val();
  
  if (!userData && !currentUser) {
    userData = {
      ip: safeUserIP,
      credits: 100,
    };
    await set(userRef, userData);
  }
  
  return { userRef, userData, currentUser };
}

export async function signUserOut() {
  return signOut(auth)
    .then(() => {
      console.log("User signed out");
    })
    .catch((error) => {
      console.error("Error signing out:", error);
    });
}


// Export the update function
export { update, set, get, db, auth, onAuthStateChanged };


// *************************************
// *************************************
// *************************************
