import React, { useState, forwardRef, useEffect, useCallback } from 'react';
import { darkStyles, lightStyles } from './styles.js';
import { detectLanguage } from './detectlang.js';
import { AceEditorComponent } from './AceSpaceBro';
import { RenderCopyToClipboardButton } from './copyclip.js';
import './App.css';
import { getUserData, update } from './FirebaseFuncs';
const {Configuration, OpenAIApi} = require("openai");


const secretKey = process.env.REACT_APP_OPEN_KEY;
const org = process.env.REACT_APP_OPEN_ORG;


export function FixThisCode({ error, code, context, isDarkMode, isCombinedFunctionTriggered, setIsCombinedFunctionTriggered, isMobile, setUserCredits, setIsLoggedIn, initialResponse, setInitialResponse, showUpgradeMessage, setShowUpgradeMessage, chatHistory, setChatHistory, openSignIn, openSignUp, gpt4, windowWidth}, ref) {
  const [chatMessage, setChatMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [isChatLoading, setChatIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleTextareaKeydown = (e) => {
    if  (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      sendChatMessage();
    }
  };
  const styles = isDarkMode ? darkStyles : lightStyles;

  const copyToClipboard = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }

  const showLoadingSpinner = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);
  
  const hideLoadingSpinner = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const showChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(true);
  }, [setChatIsLoading]);
  
  const hideChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(false);
  }, [setChatIsLoading]);
  
  const appendChatMessage = useCallback((message, isUserMessage) => {
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { message, isUserMessage },
    ]);
  }, [setChatHistory]);
  

  function renderChatSegment(segment, index, isUserMessage) {
    const ram = '```';
    if (segment.startsWith(ram) && segment.endsWith(ram)) {
      const code = segment.slice(3, -3);
      const type = detectLanguage(code)
      
      const increaseBy = 1/(windowWidth/500) * 6
  
      return (
        <div key={index} style={{ position: 'relative', width: `10${increaseBy}%`, marginLeft: '-13px', }}>
        <label>{type}</label>
        <AceEditorComponent code={code} type={type} isDarkMode={isDarkMode} />
        {RenderCopyToClipboardButton(code, isDarkMode, isCopied, setIsCopied, copyToClipboard)}
    </div>
      );
    } else {
      return (
        <div key={index} id="chat-history" style={isUserMessage ? styles.userMessage : styles.botMessage}>
          {segment}
        </div>
      );
    }
  }

  function renderSegment(segment, index) {
    const ram = '```';
    if (segment.startsWith(ram) && segment.endsWith(ram)) {
      const code = segment.slice(3, -3);
      const type = detectLanguage(code) 
      const increaseBy = 1/(windowWidth/500) * 5
  
      return (
        <div key={index} style={{ position: 'relative', width: `10${increaseBy}%`, marginLeft: '-12px'}}>
          <label>{type}</label>
          <AceEditorComponent code={code} type={type} isDarkMode={isDarkMode} />
          {RenderCopyToClipboardButton(code, isDarkMode, isCopied, setIsCopied, copyToClipboard)}
    </div>
      );
    } else {
      return (
        <pre key={index} className="response" style={styles.response}>
          {segment}
        </pre>
      );
    }
  }
  
  const clearInitialResponse = useCallback(() => {
    setInitialResponse('');
  }, [setInitialResponse]);

  function UpgradeMessage() {
    return (
      <div>
        <p>You have insufficient credits. Subscribe to continue:</p>
          <stripe-buy-button
            buy-button-id="buy_btn_1MrtDZET5bakQRi3AO3mk56T"
            publishable-key={process.env.REACT_APP_STRIPE_PUB_KEY}
          ></stripe-buy-button>
      </div>
    );
  }

  // *********************************************************************
  // Generate Response Function
  // *********************************************************************
  const generateResponse = useCallback(async () => {
    showLoadingSpinner();
    try {
      const { userRef, userData, currentUser } = await getUserData();
      setIsLoggedIn(Boolean(currentUser));
      const string = JSON.stringify({ error, code, context });
      localStorage.setItem("error", error);
      localStorage.setItem("code", code);
      localStorage.setItem("context", context);
      let creditsUsed = string.length / 200;
      
      if (creditsUsed >= 80) {
        setInitialResponse(`Input too long: ${string.length}. Max input length for this model is 16,000 characters`);
      } else {
        if (userData.credits >= 5) {
          let data;
          let usedUp;
          if (gpt4 === true) {
            data = await prompt4(error, code, context);
            // Calculate used credits and update user's credits for gpt-4
            const newVar = 200 / 15
            creditsUsed = creditsUsed * 15
            usedUp = creditsUsed + (data.length / newVar);
          } else {
            data = await prompt(error, code, context);
            // Calculate used credits and update user's credits
            usedUp = creditsUsed + (data.length / 200);
          }
          const newCreds = userData.credits - usedUp;
          const displayCreds = Math.round(newCreds * 100) / 100;
          // Deduct credits after response generation
          await update(userRef, {
            credits: newCreds,
          });
          setUserCredits(displayCreds);
          setInitialResponse(data);
          setShowUpgradeMessage(false);
          localStorage.setItem("showUp", "false");
          localStorage.setItem("initialResponse", data);
        } else {
          if (currentUser) {
            setShowUpgradeMessage(true);
            localStorage.setItem("showUp", "true");
          } else {
            // Handle the case when the user has insufficient credits
            setInitialResponse(`Insufficient credits. Sign in or Sign up at the top to continue.`);
            localStorage.setItem("initialResponse", initialResponse);
            setShowUpgradeMessage(false);
            localStorage.setItem("showUp", "false");
          }
        }
      }
    } catch (error) {
      setInitialResponse(error.message);
    } finally {
      hideLoadingSpinner();
    }
  }, [error, code, context, initialResponse, setInitialResponse, showLoadingSpinner, hideLoadingSpinner, setUserCredits, setIsLoggedIn, setShowUpgradeMessage, gpt4]);


  // *********************************************************************
  // Generate Chat Response Function
  // *********************************************************************
  const sendChatMessage = async () => {
    if (!chatMessage) return;
  
    let fullContext =
      code + context + error + initialResponse + chatHistory.map((item) => item.message).join(' ');
  
    let creditsUsed = (fullContext.length + chatMessage.length) / 200;
    if (creditsUsed >= 80) {
      const maxLength = 16000 - chatMessage.length;
      const truncatedFullContext = fullContext.slice(-maxLength);
      fullContext = truncatedFullContext;
      creditsUsed = 80;
    }
  
    try {
      const { userRef, userData, currentUser } = await getUserData();
      setIsLoggedIn(Boolean(currentUser));
  
      if (userData.credits >= 5) {
        showChatLoadingSpinner();
        appendChatMessage(chatMessage, true);
        setChatMessage('');
        let data;
        if (gpt4 === true) {
          data = await chat4(fullContext, chatMessage);
        } else {
          data = await chat(fullContext, chatMessage);
        }
        appendChatMessage(data, false);
  
        localStorage.setItem("chatHistory", JSON.stringify([...chatHistory, { message: chatMessage, isUserMessage: true }, { message: data, isUserMessage: false }]));
  
        const usedUp = creditsUsed + (data.length / 200);
        const newCreds = userData.credits - usedUp;
        const displayCreds = Math.round(newCreds * 100) / 100;
  
        await update(userRef, {
          credits: newCreds,
        });
        setUserCredits(displayCreds);
      } else {
        if (currentUser) {
          setShowUpgradeMessage(true);
          localStorage.setItem("showUp", "true");
        } else {
          appendChatMessage(`Insufficient credits. Sign in or Sign up at the top to continue.`, false);
          setShowUpgradeMessage(false);
          localStorage.setItem("showUp", "false");
        }
      }
    } catch (error) {
      appendChatMessage(error.message, false);
      localStorage.setItem("chatHistory", JSON.stringify([...chatHistory, { message: error.message, isUserMessage: false }]));
    } finally {
      hideChatLoadingSpinner();
    }
  };  
  
  const clearResponse = useCallback(() =>  {
    clearInitialResponse();
    localStorage.setItem("initialResponse", "");
    localStorage.setItem("showUp", "false");
    setShowUpgradeMessage(false);
  }, [clearInitialResponse, setShowUpgradeMessage]);
  
  const clearChat = useCallback(() =>  {
    setChatHistory([])
    localStorage.removeItem("chatHistory");
    localStorage.setItem("showUp", "false");
    setShowUpgradeMessage(false);
  }, [setShowUpgradeMessage, setChatHistory]);
  
  const combinedFunction = useCallback(() => {
    clearInitialResponse();
    generateResponse();
    localStorage.setItem("initialResponse", "");
  }, [clearInitialResponse, generateResponse]);

  useEffect(() => {
    if (isCombinedFunctionTriggered) {
      combinedFunction();
      setIsCombinedFunctionTriggered(false); // This line will reset the isCombinedFunctionTriggered state to false
    }
  }, [isCombinedFunctionTriggered, combinedFunction, setIsCombinedFunctionTriggered]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <button id="submit" style={styles.button} onClick={combinedFunction}>Submit</button>
      <div className="loading-spinner" id="loading-spinner" style={{ ...styles.loadingSpinner, display: isLoading ? 'block' : 'none', paddingLeft: '20px' }}>
        <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
          <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" style={styles.loadingSpinnerCircle} />
        </svg>
      </div>
    </div>
    <div className="response-container" id="response-container" style={{ ...styles.responseContainer, paddingTop: "15px", marginRight: "-12px", marginLeft: "-12px"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <h3 style={{ ...styles.responseContainerH3, marginLeft: "-6px", paddingTop: "-4px" }}>Response:</h3>
          <button id="clear-all" className="link-button" onClick={clearResponse} style={{ marginTop: "-20px" }}>Clear</button>
        </div>
        {showUpgradeMessage && <UpgradeMessage />}
        {initialResponse.split(/(```[\s\S]*?```)/).map((segment, index) => renderSegment(segment, index))}
      </div>
      <div className="response-container" id="chat-container" style={{ ...styles.chatResponseContainer, paddingTop: "15px", marginRight: "-12px", marginLeft: "-12px"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <h3 style={{ ...styles.responseContainerH3, marginLeft: "-6px"}}>Chat:</h3>
          <button id="clear-chat" className="link-button" onClick={clearChat} style={{ marginTop: "-20px" }}>Clear</button>
        </div>
        {chatHistory.flatMap((item, index) => {
            const segments = item.message.split(/(```[\s\S]*?```)/);
            return segments.map((segment, segmentIndex) =>
              renderChatSegment(segment, `${index}-${segmentIndex}`, item.isUserMessage)
            );
          })}
        <label htmlFor="chat-message" style={{ ...styles.yourmsgLabel, marginLeft: "-4px"}}>
          Your message:
        </label>
        <textarea
          placeholder={"Chat about your code or anything you want... \n\n3 ticks ``` around code ``` creates a code editor - Edit code on-page" }
          id="chat-message"
          rows={isMobile ? "8" : "5" }
          style={{ ...styles.textarea, marginLeft: "-15px", paddingRight: "20px",paddingLeft: "8px"}}
          value={chatMessage}
          onChange={(e) => setChatMessage(e.target.value)}
          onKeyDown={handleTextareaKeydown}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}> 
        <button id="chat-submit" style={{ ...styles.button, marginLeft: "-6px"}} onClick={sendChatMessage}>Send</button>
        <div className="loading-spinner" id="loading-spinner" style={{ ...styles.loadingSpinner, display: isChatLoading ? 'block' : 'none', paddingLeft: '20px'  }}>
          <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
            <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" style={styles.loadingSpinnerCircle} />
          </svg>
        </div>
      </div>
      </div>
    </>
  );
}


async function prompt(error, code, context) {
  const openai = new OpenAIApi(new Configuration({
    organization: org,
    apiKey: secretKey,
  }));

  try {
    const choices = await openai.createChatCompletion({
      model: "gpt-3.5-turbo-0301",
      messages: [
        {role: "user", content: `I'm getting this error: "\n${error}\n"`},
        {role: "user", content: `Here's the code that I think is causing the error: "\n${code}\n"`},
        {role: "user", content: `${context}`},
      ],
      temperature: 0,
    }).then((res) => res.data.choices);

    const completionResponse = choices[0] && choices[0].message && choices[0].message.content;

    if (completionResponse) {
      return completionResponse;
    } else {
      throw new Error("Unable to generate text");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function chat(context, message) {
  const openai = new OpenAIApi(new Configuration({
    organization: org,
    apiKey: secretKey,
  }));

  try {
    const choices = await openai.createChatCompletion({
      model: "gpt-3.5-turbo-0301",
      messages: [
        {role: "system", content: `Previous messages:\n...${context}\n\nNew Message: `},
        {role: "user", content: `${message}`},
      ],
      temperature: 0,
    }).then((res) => res.data.choices);

    const chatResponse = choices[0] && choices[0].message && choices[0].message.content;

    if (chatResponse) {
      return chatResponse;
    } else {
      throw new Error("Unable to generate text");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function prompt4(error, code, context) {
  const openai = new OpenAIApi(new Configuration({
    organization: org,
    apiKey: secretKey,
  }));

  try {
    const choices = await openai.createChatCompletion({
      model: "gpt-4-0314",
      messages: [
        {role: "user", content: `I'm getting this error: "\n${error}\n"`},
        {role: "user", content: `Here's the code that I think is causing the error: "\n${code}\n"`},
        {role: "user", content: `${context}`},
      ],
      temperature: 0,
    }).then((res) => res.data.choices);

    const completionResponse = choices[0] && choices[0].message && choices[0].message.content;

    if (completionResponse) {
      return completionResponse;
    } else {
      throw new Error("Unable to generate text");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function chat4(context, message) {
  const openai = new OpenAIApi(new Configuration({
    organization: org,
    apiKey: secretKey,
  }));

  try {
    const choices = await openai.createChatCompletion({
      model: "gpt-4-0314",
      messages: [
        {role: "system", content: `Previous messages:\n...${context}\n\nNew Message: `},
        {role: "user", content: `${message}`},
      ],
      temperature: 0,
    }).then((res) => res.data.choices);

    const chatResponse = choices[0] && choices[0].message && choices[0].message.content;

    if (chatResponse) {
      return chatResponse;
    } else {
      throw new Error("Unable to generate text");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default forwardRef(FixThisCode);
/** 
magick -background none -size 960x960 xc:black ( xc:darkred -duplicate 1 +append ) xc:gold ( xc:teal -duplicate 2 +append ) -modulate 100,100,"%[fx:rand()*200]" xc:white -scale x1 +append -write mpr:clut +delete radial-gradient: mpr:clut -clut -scale 100x4% -wave "%[fx:rand()*24+24]"x"%[fx:w/ceil(rand()*4+1)]" -extent "%[w]x%[w]" -roll +0+"%[fx:(rand()*w*0.05)+(w*0.51)]" ( +clone -blur 0x4 ) -insert 0 -composite -duplicate "%[fx:floor(rand()*3+3)*2-1]" -set option:rot "%[fx:180/n]" -virtual-pixel tile -virtual-pixel none -distort SRT "%[fx:t*360/n]" +repage -flatten -extent 100x50% ( +clone -rotate 180 ) -append +channel -virtual-pixel none -distort SRT "0.96 %[fx:rand()*360]" ( +clone -flop ) +repage -insert "%[fx:round(rand())]" -background black -flatten -brightness-contrast 20,20 -normalize dragonFire.png
*/